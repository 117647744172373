body {
    background-color: wheat;
}
.card {
    background-color: white;
}
.blog-header-logo {
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    font-size: 2.25rem;
}
.text-dark {
    color: #343a40!important;
}
.box-shadow {
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, .05);
}
.logolink {
    text-decoration: none;
}